/* tslint:disable */
/* eslint-disable */
/**
 * Resource API
 * Flow Graph Engine storage api for uploading binary inputs and downloading binary outputs.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, {
  AxiosPromise,
  AxiosInstance,
  AxiosRequestConfig,
} from 'axios';
import { Configuration } from '../configuration';
// URLSearchParams not necessarily used
// @ts-ignore
import { URL, URLSearchParams } from 'url';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common';
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from '../base';
// @ts-ignore
import { CompleteUploadErrorResponse } from '../models';
// @ts-ignore
import { CompleteUploadRequest } from '../models';
// @ts-ignore
import { CompleteUploadResponse } from '../models';
// @ts-ignore
import { CompleteUploadsRequest } from '../models';
// @ts-ignore
import { CompleteUploadsResponse } from '../models';
// @ts-ignore
import { CreateUploadRequest } from '../models';
// @ts-ignore
import { CreateUploadsRequest } from '../models';
// @ts-ignore
import { CreateUploadsResponse } from '../models';
// @ts-ignore
import { ErrorResponse } from '../models';
// @ts-ignore
import { GetDownloadUrlResponse } from '../models';
// @ts-ignore
import { GetDownloadUrlsRequest } from '../models';
// @ts-ignore
import { GetDownloadUrlsResponse } from '../models';
// @ts-ignore
import { GetUploadUrlsForResourceResponse } from '../models';
// @ts-ignore
import { GetUploadUrlsRequest } from '../models';
// @ts-ignore
import { GetUploadUrlsResponse } from '../models';
// @ts-ignore
import { Resource } from '../models';
// @ts-ignore
import { UploadWithUrls } from '../models';
/**
 * ResourcesApi - axios parameter creator
 * @export
 */
export const ResourcesApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Complete a previously started upload.
     * @summary Complete an upload
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CompleteUploadRequest} [completeUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeUpload: async (
      spaceId: string,
      region?: string,
      completeUploadRequest?: CompleteUploadRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('completeUpload', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/uploads:complete`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeUploadRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Complete uploads across multiple resources.
     * @summary Complete multiple uploads
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CompleteUploadsRequest} [completeUploadsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeUploads: async (
      spaceId: string,
      region?: string,
      completeUploadsRequest?: CompleteUploadsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('completeUploads', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/uploads:batch-complete`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeUploadsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create a new upload for a single resource. Optionally can retrieve presigned URLs for upload, see getUploadUrlsForResource operation for details and limits.
     * @summary Create a new upload
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {CreateUploadRequest} [createUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUpload: async (
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      createUploadRequest?: CreateUploadRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('createUpload', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/uploads`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (expirationInMinutes !== undefined) {
        localVarQueryParameter['expirationInMinutes'] = expirationInMinutes;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUploadRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create uploads for multiple resources.
     * @summary Create one or more new uploads
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CreateUploadsRequest} [createUploadsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUploads: async (
      spaceId: string,
      region?: string,
      createUploadsRequest?: CreateUploadsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('createUploads', 'spaceId', spaceId);
      const localVarPath = `/spaces/{spaceId}/uploads:batch-create`.replace(
        `{${'spaceId'}}`,
        encodeURIComponent(String(spaceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUploadsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a presigned URL to download the resource specified by the `resourceId`.
     * @summary Get a download URL for a resource
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {string} [ifModifiedSince] If the requested object has not been modified since the time specified in this field, an entity will not be returned from the server; instead, a 304 (not modified) response will be returned without any message-body.
     * @param {string} [ifNoneMatch] If the value of this header matches the ETag of the object, an entity will not be returned from the server; instead a 304 (not modified) response will be returned without any message-body.
     * @param {string} [responseCacheControl] Customizes the Cache-Control header in the response.
     * @param {string} [responseContentDisposition] Customizes the Content-Disposition header in the response.
     * @param {string} [responseContentType] Customizes the Content-Type header in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUrl: async (
      spaceId: string,
      resourceId: string,
      region?: string,
      expirationInMinutes?: number,
      ifModifiedSince?: string,
      ifNoneMatch?: string,
      responseCacheControl?: string,
      responseContentDisposition?: string,
      responseContentType?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('getDownloadUrl', 'spaceId', spaceId);
      // verify required parameter 'resourceId' is not null or undefined
      assertParamExists('getDownloadUrl', 'resourceId', resourceId);
      const localVarPath =
        `/spaces/{spaceId}/resources/{resourceId}/download-url`
          .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)))
          .replace(`{${'resourceId'}}`, encodeURIComponent(String(resourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (expirationInMinutes !== undefined) {
        localVarQueryParameter['expirationInMinutes'] = expirationInMinutes;
      }

      if (responseCacheControl !== undefined) {
        localVarQueryParameter['responseCacheControl'] = responseCacheControl;
      }

      if (responseContentDisposition !== undefined) {
        localVarQueryParameter['responseContentDisposition'] =
          responseContentDisposition;
      }

      if (responseContentType !== undefined) {
        localVarQueryParameter['responseContentType'] = responseContentType;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      if (ifModifiedSince !== undefined && ifModifiedSince !== null) {
        localVarHeaderParameter['If-Modified-Since'] = String(ifModifiedSince);
      }

      if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
        localVarHeaderParameter['If-None-Match'] = String(ifNoneMatch);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get presigned download URLs for multiple resources. Different storage providers may impose different limits on the number of URLs to retrieve in one request. OSS limits this to 25.
     * @summary Get download URLs for multiple resources
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {GetDownloadUrlsRequest} [getDownloadUrlsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUrls: async (
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      getDownloadUrlsRequest?: GetDownloadUrlsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('getDownloadUrls', 'spaceId', spaceId);
      const localVarPath =
        `/spaces/{spaceId}/resources:batch-get-download-urls`.replace(
          `{${'spaceId'}}`,
          encodeURIComponent(String(spaceId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (expirationInMinutes !== undefined) {
        localVarQueryParameter['expirationInMinutes'] = expirationInMinutes;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getDownloadUrlsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get the resource specified by the `resourceId`.
     * @summary Get a resource
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResource: async (
      spaceId: string,
      resourceId: string,
      region?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('getResource', 'spaceId', spaceId);
      // verify required parameter 'resourceId' is not null or undefined
      assertParamExists('getResource', 'resourceId', resourceId);
      const localVarPath = `/spaces/{spaceId}/resources/{resourceId}`
        .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)))
        .replace(`{${'resourceId'}}`, encodeURIComponent(String(resourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get upload URLs for multiple resources in one request. Different storage providers may impose limits on the number of objects, URLs per object, or total number of URLs that can be retrieved in one go. OSS limits the total number of URLs to 25 per request.
     * @summary Get upload URLs for multiple resources
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {GetUploadUrlsRequest} [getUploadUrlsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadUrls: async (
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      getUploadUrlsRequest?: GetUploadUrlsRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('getUploadUrls', 'spaceId', spaceId);
      const localVarPath =
        `/spaces/{spaceId}/resources:batch-get-upload-urls`.replace(
          `{${'spaceId'}}`,
          encodeURIComponent(String(spaceId)),
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (expirationInMinutes !== undefined) {
        localVarQueryParameter['expirationInMinutes'] = expirationInMinutes;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getUploadUrlsRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get presigned URLs to upload one or more parts of the resource to.
     * @summary Get upload URLs
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {number} [numParts] The max number of parts to retrieve.
     * @param {number} [partId] The ID of the first part to retrieve an upload URL for.
     * @param {string} [uploadId] The upload ID as previously returned when an upload was created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadUrlsForResource: async (
      spaceId: string,
      resourceId: string,
      region?: string,
      expirationInMinutes?: number,
      numParts?: number,
      partId?: number,
      uploadId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'spaceId' is not null or undefined
      assertParamExists('getUploadUrlsForResource', 'spaceId', spaceId);
      // verify required parameter 'resourceId' is not null or undefined
      assertParamExists('getUploadUrlsForResource', 'resourceId', resourceId);
      const localVarPath =
        `/spaces/{spaceId}/resources/{resourceId}/upload-urls`
          .replace(`{${'spaceId'}}`, encodeURIComponent(String(spaceId)))
          .replace(`{${'resourceId'}}`, encodeURIComponent(String(resourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: 'GET',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (expirationInMinutes !== undefined) {
        localVarQueryParameter['expirationInMinutes'] = expirationInMinutes;
      }

      if (numParts !== undefined) {
        localVarQueryParameter['numParts'] = numParts;
      }

      if (partId !== undefined) {
        localVarQueryParameter['partId'] = partId;
      }

      if (uploadId !== undefined) {
        localVarQueryParameter['uploadId'] = uploadId;
      }

      if (region !== undefined && region !== null) {
        localVarHeaderParameter['region'] = String(region);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResourcesApi - functional programming interface
 * @export
 */
export const ResourcesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    ResourcesApiAxiosParamCreator(configuration);
  return {
    /**
     * Complete a previously started upload.
     * @summary Complete an upload
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CompleteUploadRequest} [completeUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeUpload(
      spaceId: string,
      region?: string,
      completeUploadRequest?: CompleteUploadRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompleteUploadResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeUpload(
        spaceId,
        region,
        completeUploadRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Complete uploads across multiple resources.
     * @summary Complete multiple uploads
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CompleteUploadsRequest} [completeUploadsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeUploads(
      spaceId: string,
      region?: string,
      completeUploadsRequest?: CompleteUploadsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CompleteUploadsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeUploads(
        spaceId,
        region,
        completeUploadsRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create a new upload for a single resource. Optionally can retrieve presigned URLs for upload, see getUploadUrlsForResource operation for details and limits.
     * @summary Create a new upload
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {CreateUploadRequest} [createUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUpload(
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      createUploadRequest?: CreateUploadRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadWithUrls>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUpload(
        spaceId,
        region,
        expirationInMinutes,
        createUploadRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Create uploads for multiple resources.
     * @summary Create one or more new uploads
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CreateUploadsRequest} [createUploadsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUploads(
      spaceId: string,
      region?: string,
      createUploadsRequest?: CreateUploadsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<CreateUploadsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUploads(
        spaceId,
        region,
        createUploadsRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get a presigned URL to download the resource specified by the `resourceId`.
     * @summary Get a download URL for a resource
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {string} [ifModifiedSince] If the requested object has not been modified since the time specified in this field, an entity will not be returned from the server; instead, a 304 (not modified) response will be returned without any message-body.
     * @param {string} [ifNoneMatch] If the value of this header matches the ETag of the object, an entity will not be returned from the server; instead a 304 (not modified) response will be returned without any message-body.
     * @param {string} [responseCacheControl] Customizes the Cache-Control header in the response.
     * @param {string} [responseContentDisposition] Customizes the Content-Disposition header in the response.
     * @param {string} [responseContentType] Customizes the Content-Type header in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadUrl(
      spaceId: string,
      resourceId: string,
      region?: string,
      expirationInMinutes?: number,
      ifModifiedSince?: string,
      ifNoneMatch?: string,
      responseCacheControl?: string,
      responseContentDisposition?: string,
      responseContentType?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDownloadUrlResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUrl(
        spaceId,
        resourceId,
        region,
        expirationInMinutes,
        ifModifiedSince,
        ifNoneMatch,
        responseCacheControl,
        responseContentDisposition,
        responseContentType,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get presigned download URLs for multiple resources. Different storage providers may impose different limits on the number of URLs to retrieve in one request. OSS limits this to 25.
     * @summary Get download URLs for multiple resources
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {GetDownloadUrlsRequest} [getDownloadUrlsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadUrls(
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      getDownloadUrlsRequest?: GetDownloadUrlsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetDownloadUrlsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUrls(
        spaceId,
        region,
        expirationInMinutes,
        getDownloadUrlsRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get the resource specified by the `resourceId`.
     * @summary Get a resource
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResource(
      spaceId: string,
      resourceId: string,
      region?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResource(
        spaceId,
        resourceId,
        region,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get upload URLs for multiple resources in one request. Different storage providers may impose limits on the number of objects, URLs per object, or total number of URLs that can be retrieved in one go. OSS limits the total number of URLs to 25 per request.
     * @summary Get upload URLs for multiple resources
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {GetUploadUrlsRequest} [getUploadUrlsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUploadUrls(
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      getUploadUrlsRequest?: GetUploadUrlsRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUploadUrlsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadUrls(
        spaceId,
        region,
        expirationInMinutes,
        getUploadUrlsRequest,
        options,
      );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
    /**
     * Get presigned URLs to upload one or more parts of the resource to.
     * @summary Get upload URLs
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {number} [numParts] The max number of parts to retrieve.
     * @param {number} [partId] The ID of the first part to retrieve an upload URL for.
     * @param {string} [uploadId] The upload ID as previously returned when an upload was created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUploadUrlsForResource(
      spaceId: string,
      resourceId: string,
      region?: string,
      expirationInMinutes?: number,
      numParts?: number,
      partId?: number,
      uploadId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<GetUploadUrlsForResourceResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getUploadUrlsForResource(
          spaceId,
          resourceId,
          region,
          expirationInMinutes,
          numParts,
          partId,
          uploadId,
          options,
        );
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration,
      );
    },
  };
};

/**
 * ResourcesApi - factory interface
 * @export
 */
export const ResourcesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ResourcesApiFp(configuration);
  return {
    /**
     * Complete a previously started upload.
     * @summary Complete an upload
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CompleteUploadRequest} [completeUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeUpload(
      spaceId: string,
      region?: string,
      completeUploadRequest?: CompleteUploadRequest,
      options?: any,
    ): AxiosPromise<CompleteUploadResponse> {
      return localVarFp
        .completeUpload(spaceId, region, completeUploadRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Complete uploads across multiple resources.
     * @summary Complete multiple uploads
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CompleteUploadsRequest} [completeUploadsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeUploads(
      spaceId: string,
      region?: string,
      completeUploadsRequest?: CompleteUploadsRequest,
      options?: any,
    ): AxiosPromise<CompleteUploadsResponse> {
      return localVarFp
        .completeUploads(spaceId, region, completeUploadsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new upload for a single resource. Optionally can retrieve presigned URLs for upload, see getUploadUrlsForResource operation for details and limits.
     * @summary Create a new upload
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {CreateUploadRequest} [createUploadRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUpload(
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      createUploadRequest?: CreateUploadRequest,
      options?: any,
    ): AxiosPromise<UploadWithUrls> {
      return localVarFp
        .createUpload(
          spaceId,
          region,
          expirationInMinutes,
          createUploadRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Create uploads for multiple resources.
     * @summary Create one or more new uploads
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {CreateUploadsRequest} [createUploadsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUploads(
      spaceId: string,
      region?: string,
      createUploadsRequest?: CreateUploadsRequest,
      options?: any,
    ): AxiosPromise<CreateUploadsResponse> {
      return localVarFp
        .createUploads(spaceId, region, createUploadsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a presigned URL to download the resource specified by the `resourceId`.
     * @summary Get a download URL for a resource
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {string} [ifModifiedSince] If the requested object has not been modified since the time specified in this field, an entity will not be returned from the server; instead, a 304 (not modified) response will be returned without any message-body.
     * @param {string} [ifNoneMatch] If the value of this header matches the ETag of the object, an entity will not be returned from the server; instead a 304 (not modified) response will be returned without any message-body.
     * @param {string} [responseCacheControl] Customizes the Cache-Control header in the response.
     * @param {string} [responseContentDisposition] Customizes the Content-Disposition header in the response.
     * @param {string} [responseContentType] Customizes the Content-Type header in the response.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUrl(
      spaceId: string,
      resourceId: string,
      region?: string,
      expirationInMinutes?: number,
      ifModifiedSince?: string,
      ifNoneMatch?: string,
      responseCacheControl?: string,
      responseContentDisposition?: string,
      responseContentType?: string,
      options?: any,
    ): AxiosPromise<GetDownloadUrlResponse> {
      return localVarFp
        .getDownloadUrl(
          spaceId,
          resourceId,
          region,
          expirationInMinutes,
          ifModifiedSince,
          ifNoneMatch,
          responseCacheControl,
          responseContentDisposition,
          responseContentType,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get presigned download URLs for multiple resources. Different storage providers may impose different limits on the number of URLs to retrieve in one request. OSS limits this to 25.
     * @summary Get download URLs for multiple resources
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {GetDownloadUrlsRequest} [getDownloadUrlsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUrls(
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      getDownloadUrlsRequest?: GetDownloadUrlsRequest,
      options?: any,
    ): AxiosPromise<GetDownloadUrlsResponse> {
      return localVarFp
        .getDownloadUrls(
          spaceId,
          region,
          expirationInMinutes,
          getDownloadUrlsRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the resource specified by the `resourceId`.
     * @summary Get a resource
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResource(
      spaceId: string,
      resourceId: string,
      region?: string,
      options?: any,
    ): AxiosPromise<Resource> {
      return localVarFp
        .getResource(spaceId, resourceId, region, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get upload URLs for multiple resources in one request. Different storage providers may impose limits on the number of objects, URLs per object, or total number of URLs that can be retrieved in one go. OSS limits the total number of URLs to 25 per request.
     * @summary Get upload URLs for multiple resources
     * @param {string} spaceId The ID of the storage space.
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {GetUploadUrlsRequest} [getUploadUrlsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadUrls(
      spaceId: string,
      region?: string,
      expirationInMinutes?: number,
      getUploadUrlsRequest?: GetUploadUrlsRequest,
      options?: any,
    ): AxiosPromise<GetUploadUrlsResponse> {
      return localVarFp
        .getUploadUrls(
          spaceId,
          region,
          expirationInMinutes,
          getUploadUrlsRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get presigned URLs to upload one or more parts of the resource to.
     * @summary Get upload URLs
     * @param {string} spaceId The ID of the storage space.
     * @param {string} resourceId The resource ID
     * @param {string} [region] The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
     * @param {number} [expirationInMinutes] Specify the desired expiry time in minutes.
     * @param {number} [numParts] The max number of parts to retrieve.
     * @param {number} [partId] The ID of the first part to retrieve an upload URL for.
     * @param {string} [uploadId] The upload ID as previously returned when an upload was created.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUploadUrlsForResource(
      spaceId: string,
      resourceId: string,
      region?: string,
      expirationInMinutes?: number,
      numParts?: number,
      partId?: number,
      uploadId?: string,
      options?: any,
    ): AxiosPromise<GetUploadUrlsForResourceResponse> {
      return localVarFp
        .getUploadUrlsForResource(
          spaceId,
          resourceId,
          region,
          expirationInMinutes,
          numParts,
          partId,
          uploadId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for completeUpload operation in ResourcesApi.
 * @export
 * @interface ResourcesApiCompleteUploadRequest
 */
export interface ResourcesApiCompleteUploadRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiCompleteUpload
   */
  readonly spaceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiCompleteUpload
   */
  readonly region?: string;

  /**
   *
   * @type {CompleteUploadRequest}
   * @memberof ResourcesApiCompleteUpload
   */
  readonly completeUploadRequest?: CompleteUploadRequest;
}

/**
 * Request parameters for completeUploads operation in ResourcesApi.
 * @export
 * @interface ResourcesApiCompleteUploadsRequest
 */
export interface ResourcesApiCompleteUploadsRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiCompleteUploads
   */
  readonly spaceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiCompleteUploads
   */
  readonly region?: string;

  /**
   *
   * @type {CompleteUploadsRequest}
   * @memberof ResourcesApiCompleteUploads
   */
  readonly completeUploadsRequest?: CompleteUploadsRequest;
}

/**
 * Request parameters for createUpload operation in ResourcesApi.
 * @export
 * @interface ResourcesApiCreateUploadRequest
 */
export interface ResourcesApiCreateUploadRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiCreateUpload
   */
  readonly spaceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiCreateUpload
   */
  readonly region?: string;

  /**
   * Specify the desired expiry time in minutes.
   * @type {number}
   * @memberof ResourcesApiCreateUpload
   */
  readonly expirationInMinutes?: number;

  /**
   *
   * @type {CreateUploadRequest}
   * @memberof ResourcesApiCreateUpload
   */
  readonly createUploadRequest?: CreateUploadRequest;
}

/**
 * Request parameters for createUploads operation in ResourcesApi.
 * @export
 * @interface ResourcesApiCreateUploadsRequest
 */
export interface ResourcesApiCreateUploadsRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiCreateUploads
   */
  readonly spaceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiCreateUploads
   */
  readonly region?: string;

  /**
   *
   * @type {CreateUploadsRequest}
   * @memberof ResourcesApiCreateUploads
   */
  readonly createUploadsRequest?: CreateUploadsRequest;
}

/**
 * Request parameters for getDownloadUrl operation in ResourcesApi.
 * @export
 * @interface ResourcesApiGetDownloadUrlRequest
 */
export interface ResourcesApiGetDownloadUrlRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly spaceId: string;

  /**
   * The resource ID
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly resourceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly region?: string;

  /**
   * Specify the desired expiry time in minutes.
   * @type {number}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly expirationInMinutes?: number;

  /**
   * If the requested object has not been modified since the time specified in this field, an entity will not be returned from the server; instead, a 304 (not modified) response will be returned without any message-body.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly ifModifiedSince?: string;

  /**
   * If the value of this header matches the ETag of the object, an entity will not be returned from the server; instead a 304 (not modified) response will be returned without any message-body.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly ifNoneMatch?: string;

  /**
   * Customizes the Cache-Control header in the response.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly responseCacheControl?: string;

  /**
   * Customizes the Content-Disposition header in the response.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly responseContentDisposition?: string;

  /**
   * Customizes the Content-Type header in the response.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrl
   */
  readonly responseContentType?: string;
}

/**
 * Request parameters for getDownloadUrls operation in ResourcesApi.
 * @export
 * @interface ResourcesApiGetDownloadUrlsRequest
 */
export interface ResourcesApiGetDownloadUrlsRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrls
   */
  readonly spaceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiGetDownloadUrls
   */
  readonly region?: string;

  /**
   * Specify the desired expiry time in minutes.
   * @type {number}
   * @memberof ResourcesApiGetDownloadUrls
   */
  readonly expirationInMinutes?: number;

  /**
   *
   * @type {GetDownloadUrlsRequest}
   * @memberof ResourcesApiGetDownloadUrls
   */
  readonly getDownloadUrlsRequest?: GetDownloadUrlsRequest;
}

/**
 * Request parameters for getResource operation in ResourcesApi.
 * @export
 * @interface ResourcesApiGetResourceRequest
 */
export interface ResourcesApiGetResourceRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiGetResource
   */
  readonly spaceId: string;

  /**
   * The resource ID
   * @type {string}
   * @memberof ResourcesApiGetResource
   */
  readonly resourceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiGetResource
   */
  readonly region?: string;
}

/**
 * Request parameters for getUploadUrls operation in ResourcesApi.
 * @export
 * @interface ResourcesApiGetUploadUrlsRequest
 */
export interface ResourcesApiGetUploadUrlsRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiGetUploadUrls
   */
  readonly spaceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiGetUploadUrls
   */
  readonly region?: string;

  /**
   * Specify the desired expiry time in minutes.
   * @type {number}
   * @memberof ResourcesApiGetUploadUrls
   */
  readonly expirationInMinutes?: number;

  /**
   *
   * @type {GetUploadUrlsRequest}
   * @memberof ResourcesApiGetUploadUrls
   */
  readonly getUploadUrlsRequest?: GetUploadUrlsRequest;
}

/**
 * Request parameters for getUploadUrlsForResource operation in ResourcesApi.
 * @export
 * @interface ResourcesApiGetUploadUrlsForResourceRequest
 */
export interface ResourcesApiGetUploadUrlsForResourceRequest {
  /**
   * The ID of the storage space.
   * @type {string}
   * @memberof ResourcesApiGetUploadUrlsForResource
   */
  readonly spaceId: string;

  /**
   * The resource ID
   * @type {string}
   * @memberof ResourcesApiGetUploadUrlsForResource
   */
  readonly resourceId: string;

  /**
   * The region targeted by the request. The usage of this header is recommended for faster processing. The region specified must match the region in which the targeted storage space was created or else the request will fail.
   * @type {string}
   * @memberof ResourcesApiGetUploadUrlsForResource
   */
  readonly region?: string;

  /**
   * Specify the desired expiry time in minutes.
   * @type {number}
   * @memberof ResourcesApiGetUploadUrlsForResource
   */
  readonly expirationInMinutes?: number;

  /**
   * The max number of parts to retrieve.
   * @type {number}
   * @memberof ResourcesApiGetUploadUrlsForResource
   */
  readonly numParts?: number;

  /**
   * The ID of the first part to retrieve an upload URL for.
   * @type {number}
   * @memberof ResourcesApiGetUploadUrlsForResource
   */
  readonly partId?: number;

  /**
   * The upload ID as previously returned when an upload was created.
   * @type {string}
   * @memberof ResourcesApiGetUploadUrlsForResource
   */
  readonly uploadId?: string;
}

/**
 * ResourcesApi - object-oriented interface
 * @export
 * @class ResourcesApi
 * @extends {BaseAPI}
 */
export class ResourcesApi extends BaseAPI {
  /**
   * Complete a previously started upload.
   * @summary Complete an upload
   * @param {ResourcesApiCompleteUploadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public completeUpload(
    requestParameters: ResourcesApiCompleteUploadRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .completeUpload(
        requestParameters.spaceId,
        requestParameters.region,
        requestParameters.completeUploadRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Complete uploads across multiple resources.
   * @summary Complete multiple uploads
   * @param {ResourcesApiCompleteUploadsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public completeUploads(
    requestParameters: ResourcesApiCompleteUploadsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .completeUploads(
        requestParameters.spaceId,
        requestParameters.region,
        requestParameters.completeUploadsRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new upload for a single resource. Optionally can retrieve presigned URLs for upload, see getUploadUrlsForResource operation for details and limits.
   * @summary Create a new upload
   * @param {ResourcesApiCreateUploadRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public createUpload(
    requestParameters: ResourcesApiCreateUploadRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .createUpload(
        requestParameters.spaceId,
        requestParameters.region,
        requestParameters.expirationInMinutes,
        requestParameters.createUploadRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create uploads for multiple resources.
   * @summary Create one or more new uploads
   * @param {ResourcesApiCreateUploadsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public createUploads(
    requestParameters: ResourcesApiCreateUploadsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .createUploads(
        requestParameters.spaceId,
        requestParameters.region,
        requestParameters.createUploadsRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a presigned URL to download the resource specified by the `resourceId`.
   * @summary Get a download URL for a resource
   * @param {ResourcesApiGetDownloadUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public getDownloadUrl(
    requestParameters: ResourcesApiGetDownloadUrlRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .getDownloadUrl(
        requestParameters.spaceId,
        requestParameters.resourceId,
        requestParameters.region,
        requestParameters.expirationInMinutes,
        requestParameters.ifModifiedSince,
        requestParameters.ifNoneMatch,
        requestParameters.responseCacheControl,
        requestParameters.responseContentDisposition,
        requestParameters.responseContentType,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get presigned download URLs for multiple resources. Different storage providers may impose different limits on the number of URLs to retrieve in one request. OSS limits this to 25.
   * @summary Get download URLs for multiple resources
   * @param {ResourcesApiGetDownloadUrlsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public getDownloadUrls(
    requestParameters: ResourcesApiGetDownloadUrlsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .getDownloadUrls(
        requestParameters.spaceId,
        requestParameters.region,
        requestParameters.expirationInMinutes,
        requestParameters.getDownloadUrlsRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the resource specified by the `resourceId`.
   * @summary Get a resource
   * @param {ResourcesApiGetResourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public getResource(
    requestParameters: ResourcesApiGetResourceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .getResource(
        requestParameters.spaceId,
        requestParameters.resourceId,
        requestParameters.region,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get upload URLs for multiple resources in one request. Different storage providers may impose limits on the number of objects, URLs per object, or total number of URLs that can be retrieved in one go. OSS limits the total number of URLs to 25 per request.
   * @summary Get upload URLs for multiple resources
   * @param {ResourcesApiGetUploadUrlsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public getUploadUrls(
    requestParameters: ResourcesApiGetUploadUrlsRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .getUploadUrls(
        requestParameters.spaceId,
        requestParameters.region,
        requestParameters.expirationInMinutes,
        requestParameters.getUploadUrlsRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get presigned URLs to upload one or more parts of the resource to.
   * @summary Get upload URLs
   * @param {ResourcesApiGetUploadUrlsForResourceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResourcesApi
   */
  public getUploadUrlsForResource(
    requestParameters: ResourcesApiGetUploadUrlsForResourceRequest,
    options?: AxiosRequestConfig,
  ) {
    return ResourcesApiFp(this.configuration)
      .getUploadUrlsForResource(
        requestParameters.spaceId,
        requestParameters.resourceId,
        requestParameters.region,
        requestParameters.expirationInMinutes,
        requestParameters.numParts,
        requestParameters.partId,
        requestParameters.uploadId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
